import React from "react";

function Footer() {
  return (
    <React.Fragment>
      <footer className="bg-white rounded shadow p-4 mb-4 mt-4">
        <div className="row">
          <div className="col-12 col-md-4 col-xl-6 mb-4 mb-md-0">
            <p className="mb-0 text-center text-lg-start">
              © 2017-<span className="current-year">2023</span>{" "}
              <a
                target="__blank"
                className="text-primary fw-normal"
                href="http://www.sistemacsistemas.com.br"
              >
                Sistemac Sistemas
              </a>
            </p>
          </div>
          <div className="col-12 col-md-8 col-xl-6 text-center text-lg-start">
            <ul className="list-inline list-group-flush list-group-borderless text-md-end mb-0">
              <li className="list-inline-item px-0 px-sm-2">
                <a target="__blank" href="http://www.sistemacsistemas.com.br">
                  Sobre
                </a>
              </li>
              <li className="list-inline-item px-0 px-sm-2">
                <a
                  target="__blank"
                  href="http://sistemacsistemas.com.br/?page_id=23"
                >
                  Contato
                </a>
              </li>
            </ul>
          </div>
        </div>
      </footer>
    </React.Fragment>
  );
}

export default Footer;
