import React, { useState, useEffect } from 'react';
import { getDataMenu } from "../components/helpers/Api";
import { getMesas } from '../services/MesaService';

const FechamentoPrint = React.forwardRef(({ pedido }, ref) => {
    const [empresa, setEmpresa] = useState({});
    const [loading, setLoading] = useState(false);
    const [mesa2, setMesa] = useState({});

    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) return "Data Inválida";
        return new Date(date).toLocaleDateString();
    };

    const formatTime = (time) => {
        if (!time || isNaN(new Date(time))) return "Hora Inválida";
        return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleGetDataMenu = async () => {
        setLoading(true);
        try {
            const res = await getDataMenu();
            const data = res.data.data;
            setEmpresa({
                nome: data.razao_social,
                taxaEntrega: data.taxa_entrega,
                cidade: data.cidade,
                logradouro: data.logradouro,
                bairro: data.bairro,
                uf: data.uf,
                numero: data.numero,
            });
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        } finally {
            setLoading(false);
        }
    };

    const handleRetirada = async () => {
        setLoading(true);
        try {
            const mesas = await getMesas("", 1, 100)
            const mesa = mesas.rows.find((cod) => cod.cod_mesa === pedido.cod_mesa);
            if (!mesa || pedido.cod_mesa === undefined) {
                pedido.endereco = "retirada no local";
            }
        } catch (error) {
            console.error("Erro ao processar a retirada:", error);
        } finally {
            setLoading(false);
        }
    };

    async function handleGetmesa() {
        try {
            const mesas = await getMesas("", 1, 100);
            console.log(mesas)
            let mesaEncontrada = mesas.rows.find((cod) => cod.cod_mesa === pedido.cod_mesa);

            if (!mesaEncontrada || (pedido.endereco && pedido.endereco.includes("undefined"))) {
                mesaEncontrada = mesas.rows.find((cod) => cod.mesadelivery === "D" && cod.descricao.includes("retirada"));
            }

            if (mesaEncontrada) {
                setMesa(mesaEncontrada);
                console.log("Mesa encontrada:", mesaEncontrada);
            } else {
                console.warn("Nenhuma mesa encontrada");
            }
        } catch (error) {
            console.error("Erro ao buscar mesa:", error);
        }
    }
    ;

    useEffect(() => {
        if (pedido) {
            handleGetDataMenu();
            handleRetirada();
            handleGetmesa();
        }
    }, [pedido]);

    const currentDate = formatDate(new Date());
    const currentTime = formatTime(new Date());

    const styles = {
        printerTicket: {
            display: 'table',
            width: '100%',
            maxWidth: '400px',
            fontWeight: 'lighter',
            lineHeight: '1.3em',
            fontFamily: 'Tahoma, Geneva, sans-serif',
            color: 'black',
            padding: '3px 8px',
            fontSize: '10px',
        },
        title: {
            fontSize: '22px',
            padding: '10px 0',
            textAlign: 'center',
        },
        thTd: {
            borderBottom: '1px dashed #707070', // Alterado para uma cor mais escura
            padding: '5px 0',
            fontSize: '10px',
        },
        td: {
            padding: '5px 0',
            fontSize: '10px',
        },
        ttu: {
            textTransform: 'uppercase',
            fontSize: '10px',
        },
        sup: {
            paddingTop: '10px',
            borderTop: '1px dashed #707070', // Alterado para uma cor mais escura
            fontSize: '10px',
        },
        center: {
            textAlign: 'center',
            fontSize: '10px',
        },
        itemRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 0',
            fontSize: '10px',
        },
        itemDescription: {
            flex: 1,
            textAlign: 'left',
            fontSize: '10px',
        },
        itemPrice: {
            minWidth: '50px',
            textAlign: 'right',
            fontSize: '10px',
        },
    };

    return (
        <div ref={ref} style={styles.printerTicket}>
            {loading ? (
                <p>Carregando dados da empresa...</p>
            ) : pedido ? (
                <>
                    <table style={{ width: '100%' }}>
                        <thead>
                            <tr>
                                <th style={{ ...styles.title, ...styles.thTd, fontSize: '26px' }} colSpan="3">
                                    {empresa.nome || 'Victor Shop'}
                                    <p style={{ fontSize: "10px", paddingTop: "10px" }}>
                                        {empresa.logradouro}, {empresa.numero} - {empresa.bairro}, {empresa.cidade} - {empresa.uf}
                                    </p>
                                </th>
                            </tr>
                            {/* <tr>
                                <div style={{ ...styles.center, marginTop: '10px', paddingLeft: "3px" }}>
                                    <p> Pedido: {pedido.numero_pedidomesa}</p>
                                </div>
                            </tr> */}
                            <tr>
                                <th style={{ ...styles.thTd, fontSize: '14px' }}> {mesa2.descricao} Pedido: {pedido.numero_pedidomesa} </th>
                                <th style={{ ...styles.thTd, fontSize: '14px' }}> { }</th>

                            </tr>
                            <tr>
                                <th colSpan="3" style={styles.thTd}>{currentDate} - {currentTime}</th>
                            </tr>
                            <tr>
                                <th colSpan="3" style={styles.thTd}>
                                    {pedido.nome_cliente || 'Nome do cliente'} <br />
                                    {pedido.telefone_cliente || '000.000.000-00'}<br />
                                    {pedido.endereco}
                                </th>
                            </tr>
                            <tr>
                                <th style={{ ...styles.ttu, ...styles.thTd }} colSpan="3">
                                    <b>Cupom não fiscal</b>
                                </th>
                            </tr>
                            <tr>
                                <th style={styles.thTd}>Descrição</th>
                                <th style={{ ...styles.thTd, textAlign: "right", paddingLeft: "100px" }}>Qtd</th>
                                <th style={{ ...styles.thTd, textAlign: 'right' }}>Valor</th>
                            </tr>
                        </thead>
                        <tbody>
                            {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.map((item, index) => (
                                <React.Fragment key={index}>
                                    <tr>
                                        <td style={styles.itemDescription}>{item.descricao}</td>
                                        <td style={{ ...styles.td, textAlign: "right", paddingLeft: "100px" }}>{item.quantidade}</td>
                                        <td style={styles.itemPrice}>R${(item.valor_unitario * item.quantidade).toFixed(2)}</td>
                                    </tr>
                                    {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                        <tr key={acompIndex}>
                                            <td style={{ paddingLeft: '10px' }}>{acomp.web_descricao}</td>
                                            <td style={{ textAlign: 'center' }}>1</td>
                                            <td style={{ textAlign: 'right' }}>R${acomp.preco?.toFixed(2)}</td>
                                        </tr>
                                    ))}
                                </React.Fragment>
                            ))}
                        </tbody>
                        <tfoot>
                            <tr>
                                <td style={{ ...styles.sup, ...styles.thTd }} colSpan="3"><b>Totais</b></td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Sub-total</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_produtos.toFixed(2)}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Taxa de serviço</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_servico.toFixed(2)}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Total</td>
                                <td style={{ ...styles.thTd, textAlign: 'right', }}> <b>R${pedido.valor_total.toFixed(2)}</b></td>
                            </tr>

                            <tr style={styles.ttu}>
                                <td style={{ ...styles.sup, ...styles.thTd }} colSpan="2"><b>Pagamentos</b></td>

                                <td style={{ ...styles.thTd, textAlign: 'right' }} >{pedido.tb_ipedidomesas[0]?.forma_pagamento}</td>
                            </tr>
                            <tr style={styles.ttu}>
                                <td style={styles.thTd} colSpan="2">Troco</td>
                                <td style={{ ...styles.thTd, textAlign: 'right' }}>R${(pedido?.troco > 0 ? pedido?.troco - pedido.valor_total : 0).toFixed(2)}</td>
                            </tr>
                        </tfoot>
                    </table>
                    <div style={{ ...styles.center, marginTop: '10px', fontSize: "2px" }}>
                        <p>www.sistemacsistemas.com.br</p>
                    </div>
                </>
            ) : (
                <p>Carregando...</p>
            )
            }
        </div >
    );
});

export default FechamentoPrint;
