import React, { useState, useEffect } from 'react';
import {
    getDataMenu,

} from "../components/helpers/Api";
const EntregaPrint = React.forwardRef(({ pedido }, ref) => {
    const [empresa, setEmpresa] = useState({});
    const [loading, setLoading] = useState(false);

    const formatDate = (date) => {
        if (!date || isNaN(new Date(date))) return "Data Inválida";
        return new Date(date).toLocaleDateString();
    };

    const formatTime = (time) => {
        if (!time || isNaN(new Date(time))) return "Hora Inválida";
        return new Date(time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    };

    const handleGetDataMenu = async () => {
        setLoading(true);
        try {
            const res = await getDataMenu();
            const data = res.data.data;
            setEmpresa({
                nome: data.razao_social,
                taxaEntrega: data.taxa_entrega,
                cidade: data.cidade,
                logradouro: data.logradouro,
                bairro: data.bairro,
                uf: data.uf,
                numero: data.numero,
            });
        } catch (error) {
            console.error("Erro ao buscar dados da empresa:", error);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        handleGetDataMenu();
    }, []);

    const currentDate = formatDate(new Date());
    const currentTime = formatTime(new Date());

    const styles = {
        printerTicket: {
            display: 'table',
            width: '100%',
            maxWidth: '400px',
            fontWeight: 'lighter',
            lineHeight: '1.3em',
            fontFamily: 'Tahoma, Geneva, sans-serif',
            fontSize: '10px',
            color: 'black',
            padding: '3px 8px'
        },
        title: {
            fontSize: '1.5em',
            padding: '15px 0',
            textAlign: 'center'
        },
        thTd: {
            borderBottom: '1px dashed #BCBCBC',
            padding: '5px 0'
        },
        ttu: {
            textTransform: 'uppercase'
        },
        sup: {
            paddingTop: '10px',
            borderTop: '1px dashed #BCBCBC'
        },
        center: {
            textAlign: 'center'
        },
        itemRow: {
            display: 'flex',
            justifyContent: 'space-between',
            padding: '3px 0'
        },
        itemDescription: {
            flex: 1,
            textAlign: 'left'
        },
        itemPrice: {
            minWidth: '50px',
            textAlign: 'right'
        }
    };
    console.log(pedido)
    return (
        <div ref={ref} style={styles.printerTicket}>
            {loading ? (
                <p>Carregando dados da empresa...</p>
            ) : (
                pedido ? (
                    <>
                        <table style={{ width: '100%' }}>
                            <thead>
                                <tr>
                                    <th style={{ ...styles.title, ...styles.thTd }} colSpan="3">
                                        {empresa.nome || 'Victor Shop'}
                                        <p style={{ fontSize: "10px" }}>{empresa.logradouro}, {empresa.numero} - {empresa.bairro}, {empresa.cidade} - {empresa.uf}</p>

                                    </th>
                                </tr>
                                <tr>
                                    <th colSpan="3" style={styles.thTd}>{currentDate} - {currentTime}</th>
                                </tr>
                                <tr>
                                    <th colSpan="3" style={styles.thTd}>
                                        {pedido.nome_cliente || 'Nome do cliente'} <br />
                                        {pedido.telefone_cliente || '000.000.000-00'}<br />
                                        {pedido.endereco}
                                    </th>
                                </tr>
                                <tr>
                                    <th style={{ ...styles.ttu, ...styles.thTd }} colSpan="3">
                                        <b>Cupom não fiscal</b>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {pedido.tb_ipedidomesas && pedido.tb_ipedidomesas.map((item, index) => (
                                    <React.Fragment key={index}>
                                        <tr>
                                            <td colSpan="3" style={styles.itemRow}>
                                                <span style={styles.itemDescription}>{item.descricao}</span>
                                                <span style={styles.itemPrice}>R${(item.valor_unitario * item.quantidade).toFixed(2)}</span>
                                            </td>
                                        </tr>
                                        {item.tb_ipedidomesaacoms && item.tb_ipedidomesaacoms.map((acomp, acompIndex) => (
                                            <tr key={acompIndex}>
                                                <td colSpan="3" style={{ paddingLeft: '10px' }}>
                                                    <span>
                                                        {acomp.web_descricao}
                                                    </span>
                                                    <span>
                                                        R${(acomp.preco)?.toFixed(2)}
                                                    </span>
                                                </td>
                                            </tr>
                                        ))}
                                        <tr>
                                            <td colSpan="3" style={styles.itemRow}>
                                                <span style={styles.itemDescription}>V.UNIT: R${item.valor_unitario.toFixed(2)}</span>
                                                <span style={styles.itemPrice}>Qtd: {item.quantidade}</span>
                                            </td>
                                        </tr>

                                    </React.Fragment>
                                ))}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <td style={{ ...styles.sup, ...styles.thTd }} colSpan="3"><b>Totais</b></td>
                                </tr>
                                <tr style={styles.ttu}>
                                    <td style={styles.thTd} colSpan="2">Sub-total</td>
                                    <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_produtos.toFixed(2)}</td>
                                </tr>
                                <tr style={styles.ttu}>
                                    <td style={styles.thTd} colSpan="2">Taxa de serviço</td>
                                    <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_servico.toFixed(2)}</td>
                                </tr>
                                <tr style={styles.ttu}>
                                    <td style={styles.thTd} colSpan="2">Total</td>
                                    <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_total.toFixed(2)}</td>
                                </tr>
                                <tr>
                                    <td style={{ ...styles.sup, ...styles.thTd }} colSpan="3"><b>Pagamentos</b></td>
                                </tr>
                                <tr style={styles.ttu}>
                                    <td style={styles.thTd} colSpan="2">{pedido.tb_ipedidomesas[0]?.forma_pagemento}</td>
                                    <td style={{ ...styles.thTd, textAlign: 'right' }}>R${pedido.valor_pago ? pedido.valor_pago.toFixed(2) : '0.00'}</td>
                                </tr>
                                <tr style={styles.ttu}>
                                    <td style={styles.thTd} colSpan="2">Troco</td>
                                    <td style={{ ...styles.thTd, textAlign: 'right' }}>R${(pedido?.troco || 0).toFixed(2)}</td>
                                </tr>
                            </tfoot>
                        </table>
                        <div style={{ ...styles.center, marginTop: '10px' }}>
                            <p>Pedido: {pedido.numero_pedidomesa}</p>
                        </div>
                        <div style={{ ...styles.center, marginTop: '10px' }}>
                            <p>Sistemac Food</p>
                        </div>
                    </>
                ) : (
                    <p>Carregando...</p>
                )
            )}
        </div>
    );
});

export default EntregaPrint;
