import React, { useEffect, useState } from "react";
import "./SelectAdditionalModal.scss";
import { Box, Modal } from "@mui/material";
import { getAdditionalsByIdProduct, getSaboressByIdProduct } from "../../../helpers/Api";
import { Load } from "../../../widgets/spinner/Spinner";
import { getTamanhoByCod } from "../../../../services/TamanhoService";
import { get } from "jquery";

const SelectAdditionalModal = ({ ...props }) => {
  const [additionals, setAdditionals] = useState([]);
  const [data, setData] = useState([]);
  const [hasIndexies, setHasIndexies] = useState([]);
  const [limitSection, setLimitSection] = useState([]);
  const [tamanhos, setTamanhos] = useState([]);
  const [sabores, setSabores] = useState([]);
  const [selectedTamanho, setSelectedTamanho] = useState(null);
  const [selectedSabores, setSelectedSabores] = useState([]);
  const [saborString, setSaborString] = useState("");
  const [price, setPrice] = useState(0);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    handleGetAdditionals();
    handleGetSabores();
    handleGetTamanhos();
  }, [props.id]);
  useEffect(() => {
    if (props.open) {
      handleGetSabores();
    }
  }, [props.open]);

  function handleGetAdditionals() {
    setHasIndexies([]);
    setData([]);
    setLimitSection([]);
    setLoading(true);

    getAdditionalsByIdProduct(props.id)
      .then((res) => {
        if (res.data.additionals) {
          const newData = res.data.additionals?.map((add) => {
            return {
              ...add,
              descricao: add.descricao,
              preco_venda_vista: add.preco_venda_vista

            }
          });
          setData(newData);
        }
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }


  function handleAddAdditionals(index, product) {

    const additionalPrice = product.preco_venda_vista;

    setPrice((prevPrice) => prevPrice + additionalPrice);

    setData((prevData) => {
      const newData = [...prevData];
      const productItem = { ...newData[index] };

      productItem.quantidade = (productItem.quantidade || 0) + 1;
      newData[index] = productItem;
      return newData;
    });

    setAdditionals((prevAdditionals) => {
      const existingAdditional = prevAdditionals.find(
        (ad) => ad.cod_produto === product.cod_produto
      );

      if (existingAdditional) {
        return prevAdditionals.map((ad) =>
          ad.cod_produto === product.cod_produto
            ? { ...ad, amount: ad.amount + 1, price: ad.price + additionalPrice }
            : ad
        );
      } else {
        return [
          ...prevAdditionals,
          {
            amount: 1,
            name: product.descricao,
            price: additionalPrice,
            cod_produto: product.cod_produto,
          },
        ];
      }
    });

    if (data[index].quantidade + 1 === data[index].web_quantidade) {
      setLimitSection((prevLimitSection) => [...prevLimitSection, index]);
    }

    setHasIndexies((prevHasIndexies) => [...prevHasIndexies, index]);
  }


  function handleSubAdditionals(indexSection, indexProduct, product) {
    const productPrice = selectedTamanho ? selectedTamanho.valor : data[indexSection].tb_produtos[indexProduct].preco_venda_vista;
    console.log(productPrice)
    setPrice((prevPrice) => productPrice);
    // if (price === 0 & props.productValue != 0) { setPrice(props.productValue) }


    // Atualizando o estado de 'data' imutavelmente
    setData((prevData) => {
      const newData = [...prevData];
      const section = { ...newData[indexSection] };
      const products = [...section.tb_produtos];
      const productItem = { ...products[indexProduct] };

      productItem.quantidade = Math.max((productItem.quantidade || 1) - 1, 0);
      products[indexProduct] = productItem;
      section.tb_produtos = products;
      section.quantidade = Math.max((section.quantidade || 1) - 1, 0);
      newData[indexSection] = section;
      return newData;
    });

    setHasIndexies((prevHasIndexies) =>
      prevHasIndexies.filter((item) => item !== indexProduct)
    );

    setAdditionals((prevAdditionals) =>
      console.log(prevAdditionals) &&
      prevAdditionals.filter((item) => item.cod_produto !== product.cod_produto)
    );

    setLimitSection((prevLimitSection) =>
      prevLimitSection.filter((item) => item !== indexSection)
    );
  }



  function handleGetTamanhos() {
    setLoading(true);
    getTamanhoByCod(props.id)
      .then((res) => {
        setTamanhos(res);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleGetSabores() {
    setLoading(true);
    getSaboressByIdProduct(props.id)
      .then((res) => {
        setSabores(res.data.product.tb_secaos);
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setLoading(false);
      });
  }

  function handleClearFields() {
    props.setFalse();
    setAdditionals([]);
    setSabores([])
    setSelectedSabores([])
    setHasIndexies([]);
    handleGetAdditionals();
    setSelectedTamanho(null)
    setPrice(0);
  }

  function handleTamanhoChange(event) {
    const tamanhoSelecionado = tamanhos.find(
      (tamanho) => tamanho.descricao === event.target.value
    );
    setSelectedTamanho(tamanhoSelecionado);

    if (tamanhoSelecionado) {
      const tamanhoValor = tamanhoSelecionado.valor;

      const saborPrices = selectedSabores.map((sabor) => {
        const selectedSabor = sabores.find((secao) =>
          secao.tb_produtos.some((produto) => produto.descricao === sabor)
        );

        if (selectedSabor) {
          const tamanhoEspecifico = selectedSabor.tb_produtos
            .flatMap((produto) => produto.tamanhos || [])
            .filter((tamanho) => tamanho.descricao === tamanhoSelecionado.descricao);

          return tamanhoEspecifico.length > 0
            ? Math.max(...tamanhoEspecifico.map((tamanho) => tamanho.valor))
            : 0;
        }
        return 0;
      });

      const maxSaborPrice = Math.max(...saborPrices, 0);
      const adicionaisValor = additionals.reduce((acc, ad) => acc + ad.price * ad.amount, 0);

      setPrice(tamanhoValor + maxSaborPrice + adicionaisValor);
    }
  }

  function handleSaborChange(event) {
    const { value, checked } = event.target;

    setSelectedSabores((prevSelectedSabores) => {
      const updatedSabores = checked
        ? [...prevSelectedSabores, value]
        : prevSelectedSabores.filter((sabor) => sabor !== value);

      // Recalcular o preço total ao alterar sabores
      const saborPrices = updatedSabores.map((sabor) => {
        const selectedSabor = sabores.find((secao) =>
          secao.tb_produtos.some((produto) => produto.descricao === sabor)
        );

        if (selectedSabor) {
          const tamanhoEspecifico = selectedSabor.tb_produtos
            .flatMap((produto) => produto.tamanhos || [])
            .filter((tamanho) => tamanho.descricao === selectedTamanho?.descricao);

          return tamanhoEspecifico.length > 0
            ? Math.max(...tamanhoEspecifico.map((tamanho) => tamanho.valor))
            : 0;
        }
        return 0;
      });

      const maxSaborPrice = Math.max(...saborPrices, 0);
      const tamanhoValor = selectedTamanho ? selectedTamanho.valor : 0;
      const adicionaisValor = additionals.reduce((acc, ad) => acc + ad.price * ad.amount, 0);

      setPrice(tamanhoValor + maxSaborPrice + adicionaisValor);

      return updatedSabores;
    });
  }

  async function getMaxPrice() {
    try {
      const maxPrice = await fetchSaborPrices(selectedSabores);
      return maxPrice
    } catch (error) {
      console.error("Erro ao obter o maior preço:", error);
    }
  }

  function fetchSaborPrices(selectedSabores) {
    return new Promise((resolve, reject) => {
      const saborPricesPromises = selectedSabores.map((sabor) => {
        const selectedSabor = sabores.find((secao) =>
          secao.tb_produtos.some((produto) => produto.descricao === sabor)
        );

        if (selectedSabor) {
          return Promise.all(
            selectedSabor.tb_produtos.map((produto) =>
              getTamanhoByCod(produto.cod_produto)
            )
          )
            .then((res) => {
              const tamanhoEspecifico = res
                .flat()
                .filter((tamanho) => tamanho.descricao === selectedTamanho.descricao);

              const maxPrice = tamanhoEspecifico.length > 0
                ? Math.max(...tamanhoEspecifico.map((tamanho) => tamanho.valor))
                : 0;

              return maxPrice;
            })
            .catch((error) => {
              console.error("Erro ao buscar tamanhos para o sabor: ", error);
              return -Infinity;
            });
        }

        return Promise.resolve(selectedTamanho.valor);
      });

      Promise.all(saborPricesPromises)
        .then((prices) => {
          const maxSaborPrice = Math.max(...prices);
          resolve(maxSaborPrice);
        })
        .catch((error) => {
          console.error("Erro ao calcular os preços dos sabores: ", error);
          reject(error);
        });
    });
  }



  useEffect(() => {
    const saboresString = selectedSabores.join(", ");
    setSaborString(saboresString);
  }, [selectedSabores]);

  async function handleSave() {
    if (tamanhos.length > 0 && !selectedTamanho) {
      alert("Por favor, selecione um tamanho antes de salvar.");
      return;
    }

    let finalPrice = 0;

    if (selectedTamanho) {
      finalPrice += selectedTamanho.valor;
    }

    if (selectedSabores.length > 0) {
      try {
        const price = await getMaxPrice(); // Aguarda a resolução da Promise
        finalPrice = price;
      } catch (error) {
        console.error("Erro ao obter o preço máximo:", error);
        return; // Impede o salvamento em caso de erro
      }
    }

    additionals.forEach((additional) => {
      finalPrice += additional.price * additional.amount;
    });

    if (finalPrice === 0) {
      finalPrice = props.productValue;
    }

    setPrice(finalPrice);
    props.setSubTotal(finalPrice);
    props.setAddiotionals(additionals);
    props.setSelectedSize(selectedTamanho);
    props.setselectedSabores(saborString);

    handleClearFields();
  }


  return (
    <Modal open={props.open} onClose={handleClearFields}>
      <Box>
        <main className="select-additional-modal">
          <section className="first-section">
            <p className="title"></p>
            <i
              className="fa-solid fa-xmark"
              role="button"
              onClick={() => handleClearFields()}
            ></i>
          </section>
          <section
            style={{
              marginTop: "20px",
            }}
          >
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                color: "#555",
                marginBottom: "10px",
              }}
            >
              Selecione um tamanho
            </p>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                gap: "15px",
                marginTop: "10px",
              }}
            >
              {tamanhos.map((tamanho, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                >
                  <input
                    type="radio"
                    name="tamanho"
                    id={`tamanho-${tamanho.cod_tamanho}`}
                    onChange={handleTamanhoChange}
                    value={tamanho.descricao}
                    style={{ marginRight: "10px" }}
                  />
                  <label htmlFor={`tamanho-${tamanho.cod_tamanho}`}>
                    {tamanho.descricao}
                  </label>
                  <span
                    style={{
                      fontWeight: "600",
                      color: "#333",
                      fontSize: "14px",
                    }}
                  >
                    R${parseFloat(tamanho.valor).toFixed(2)}
                  </span>
                </div>
              ))}
            </div>
          </section>
          {Array.isArray(sabores) && sabores.length && sabores.map((secao) => (
            <section
              key={secao.cod_secao}
              style={{
                marginTop: "20px",
              }}
            >
              <p
                style={{
                  fontSize: "16px",
                  fontWeight: "600",
                  color: "#555",
                  marginBottom: "10px",
                }}
              >
                Selecione um Sabor
              </p>
              <div
                style={{
                  display: "grid",
                  gridTemplateColumns: "repeat(auto-fit, minmax(150px, 1fr))",
                  gap: "15px",
                  marginTop: "10px",
                }}
              >
                {secao.tb_produtos.map((produto, index) => (
                  produto.cod_produto !== props.id && <div
                    key={index}
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      padding: "10px",
                      border: "1px solid #e0e0e0",
                      borderRadius: "5px",
                      backgroundColor: "#f9f9f9",
                      transition: "background-color 0.3s ease",
                    }}
                    onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                    onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                  >
                    <label>
                      <input
                        type="checkbox"
                        value={produto.descricao}
                        onChange={handleSaborChange}
                      />
                      {produto.descricao}
                    </label>
                  </div>
                ))}
              </div>
            </section>
          ))}
          <section className="second-section">
            <div className="additionals">
              <p className="sub-title" style={{ textAlign: "center", borderBottom: "2px solid #f2f2f2" }}>Adicionais</p>
              {data?.map((item, index) => (
                <div
                  key={index}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    padding: "10px",
                    border: "1px solid #e0e0e0",
                    borderRadius: "5px",
                    backgroundColor: "#f9f9f9",
                    transition: "background-color 0.3s ease",
                  }}
                  onMouseEnter={(e) => (e.currentTarget.style.backgroundColor = "#f0f0f0")}
                  onMouseLeave={(e) => (e.currentTarget.style.backgroundColor = "#f9f9f9")}
                >
                  <div className="item-information">
                    <span className="item-information-title">{item.descricao}</span>
                    <br />
                    <span className="item-information-price">R${parseFloat(item.preco_venda_vista).toFixed(2)}</span>
                  </div>
                  <div className="item-action">
                    <div className="amount" style={{ display: "flex", alignItems: "center" }}>
                      <i
                        onClick={() => {
                          if (hasIndexies.includes(index)) handleSubAdditionals(index, item);
                        }}
                        className="fa fa-minus"
                        style={{
                          color: hasIndexies.includes(index) ? "" : "gray",
                          opacity: hasIndexies.includes(index) ? 1 : 0.5,
                          cursor: hasIndexies.includes(index) ? "pointer" : "not-allowed",
                          marginRight: "5px"
                        }}
                      ></i>
                      <span id={`product-${item.cod_produto}`} style={{ fontWeight: "600", color: "#333", fontSize: "14px" }}>
                        {item.quantidade || 0}
                      </span>
                      <i
                        onClick={() => {
                          if (!hasIndexies.includes(index) && !limitSection.includes(index)) handleAddAdditionals(index, item);
                        }}
                        className="fa fa-plus"
                        style={{
                          color: hasIndexies.includes(index) || limitSection.includes(index) ? "gray" : "",
                          opacity: hasIndexies.includes(index) || limitSection.includes(index) ? 0.5 : 1,
                          cursor: hasIndexies.includes(index) || limitSection.includes(index) ? "not-allowed" : "pointer",
                          marginLeft: "5px"
                        }}
                      ></i>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </section>

          <button className="save" onClick={handleSave}>
            Salvar
          </button>
        </main>
      </Box>
    </Modal>
  );
};

export default SelectAdditionalModal;

